import React, { useEffect, useRef, useState } from 'react';
import { AppComponent, ComponentDefinition } from 'utils/components';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { format as formatDate } from 'date-fns';
import { useForm } from 'react-hook-form';
import 'assets/scss/grid.css';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { executeAction } from 'utils/httpRequestManager';
import Loading from 'components/parts/Loading';
import { trimAtSign } from 'utils/string';
import Input from 'components/Forms/FormComponents/Input';
import { useDispatch } from 'react-redux';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import DynamicComponent from 'components/DynamicComponent';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import FileDownload from 'js-file-download';

type ColumnDefinition = {
	i_tvf_col: string;
	n_tvf_col: string;
	inactive: string | null;
	i_table_valued_function: string;
	i_col_type: string;
	col_type_system: string;
	col_length: number;
	col_precision: number | null;
	col_scale: number | null;
	alignment: 'RIGHT' | null;
	thousands_separator: boolean;
	i_component: string;
	translate: boolean;
	default_width: number;
	editable: boolean;
	column_position: number;
	column_hidden: boolean;
	column_width: number;
};

type FilterDefinition = {
	i_component: string;
	i_col_type: string;
	i_table_valued_function_parameter: string;
	n_table_valued_function_parameter: string;
	n_filter: string;
	parameter_length: string | null;
	parameter_order: string | null;
	parameter_precision: string | null;
	parameter_scale: string | null;
	parameter_type_system: string;
	default_value: string | null;
	visible_for_user: boolean;
};

const ButtonCellRenderer = (props: {
	value: string;
	componentDefinitions: ComponentDefinition[];
}) => {
	const { value } = props;
	if (!value) return null;

	const def = JSON.parse(value)[0];

	const component = {
		i_component: def.i_component,
		variables: def.variables,
		datasource_name: null,
		datasource_parameters: null
	};

	return <DynamicComponent component={component} has_definitions={false} definition={undefined} />;
};

function getColumnDefinitions(
	columnDefinitions: ColumnDefinition[],
	navigate: NavigateFunction,
	dispatch: Dispatch<UnknownAction>,
	t: TFunction<string>
) {
	return columnDefinitions
		.filter((col) => !col.column_hidden)
		.sort((a, b) => a.column_position - b.column_position)
		.map((col) => {
			const editable = col.editable;
			const def: ColDef = {
				colId: col.i_tvf_col,
				headerName: t('grid.columns.' + col.n_tvf_col),
				width: col.default_width,
				filter: true,
				editable: editable,
				valueGetter: (params) => {
					return params.data[col.n_tvf_col];
				},
				valueSetter: (params) => {
					if (!editable) {
						return false;
					}

					params.data[col.n_tvf_col] = params.newValue;

					if (params.data[params.data.datasource_id] !== null) {
						const newData: any = {};
						newData[col.n_tvf_col] = params.newValue;

						const where: any = {};
						where[params.data.datasource_id] = params.data[params.data.datasource_id];

						executeAction(
							{
								action: 'UPDATE',
								datasource_name: params.data.datasource_name,
								where: where,
								data: newData
							},
							navigate,
							dispatch
						);
					} else {
						const newData: any = {};
						Object.entries(params.data).forEach(([key, value]) => {
							if (![params.data.datasource_id, 'datasource_id', 'datasource_name'].includes(key)) {
								newData[key] = value;
							}
						});

						executeAction(
							{
								action: 'INSERT',
								datasource_name: params.data.datasource_name,
								data: newData
							},
							navigate,
							dispatch
						);
					}

					return true;
				}
			};
			if (col.n_tvf_col.includes('i_component')) {
				def.cellRenderer = ButtonCellRenderer;
			}

			if (col.i_col_type === 'NUMERIC') {
				def.type = 'numericColumn';
				def.cellDataType = 'number';
				def.valueFormatter = (params: { value: number }) => {
					if (!params.value) {
						return '';
					}
					if (col.thousands_separator) {
						return params.value.toLocaleString('en-US', {
							maximumFractionDigits: col.col_scale ?? 0,
							minimumFractionDigits: col.col_scale ?? 0
						});
					} else {
						return params.value.toFixed(col.col_scale ?? 0);
					}
				};
			} else if (col.alignment === 'RIGHT') {
				def.type = 'rightAligned';
			} else if (['TIME', 'DATETIME', 'DATE'].includes(col.i_col_type)) {
				def.filter = 'agDateColumnFilter';
				def.cellDataType = 'date';
				def.valueFormatter = (params: { value: Date | null }) => {
					if (params.value === null) {
						return '';
					}
					let format = '';
					if (col.i_col_type.includes('DATE')) {
						format += 'yyyy.MM.dd';
					}
					if (col.i_col_type.includes('TIME')) {
						format += ' HH:mm:ss';
					}
					return formatDate(params.value, format);
				};
			} else if (col.i_col_type === 'BIT') {
				def.cellDataType = 'boolean';
			}

			return def;
		});
}

function parseData(data: object[], columns: ColDef[]) {
	const numericColumns = columns.filter((c) => c.cellDataType === 'number').map((c) => c.field);
	const dateColumns = columns.filter((c) => c.cellDataType === 'date').map((c) => c.field);

	return JSON.parse(JSON.stringify(data), (key, value) => {
		if (value === null) {
			return null;
		}
		if (numericColumns.includes(key)) {
			return parseFloat(value);
		} else if (dateColumns.includes(key)) {
			return new Date(value);
		}
		return value;
	});
}

function parseFilters(filters: FilterDefinition[], t: TFunction<string>) {
	return filters
		.filter((filter) => filter.visible_for_user)
		.map((filter) => {
			filter.n_table_valued_function_parameter = trimAtSign(
				filter.n_table_valued_function_parameter
			);
			filter.n_filter = t('grid.filter.' + filter.n_table_valued_function_parameter);

			return filter;
		})
		.sort((a, b) => parseInt(a.parameter_order ?? '0') - parseInt(b.parameter_order ?? '0'));
}

export default function Grid(props: {
	component: AppComponent;
	definition: ComponentDefinition | undefined;
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [rowData, setRowData] = useState([] as object[] | null);
	const [colDefs, setColDefs] = useState(null as ColDef[] | null);
	const [filterDefs, setFilterDefs] = useState(null as FilterDefinition[] | null);
	const [filterData, setFilterData] = useState({} as object);
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, reset } = useForm();

	function onSubmit(data: object) {
		setFilterData(data);
	}

	function onReset() {
		setFilterData({});
		reset();
	}

	useEffect(() => {
		setLoading(true);
		if (props.component.datasource_name !== null) {
			let parameters: object = filterData;
			if (props.component.datasource_parameters !== null) {
				parameters = { ...filterData, ...JSON.parse(props.component.datasource_parameters) };
			}
			const dataRequest = sendHttpRequest(
				'POST',
				'/grid/data',
				{ datasource_name: props.component.datasource_name, parameters: parameters },
				navigate,
				dispatch
			);
			const definitionRequest = sendHttpRequest(
				'POST',
				'/grid/column-definitions',
				{ datasource_name: props.component.datasource_name },
				navigate,
				dispatch
			);
			Promise.all([dataRequest, definitionRequest]).then(([dataResponse, definitionResponse]) => {
				const columnDefinitions = getColumnDefinitions(
					definitionResponse.data[0] as ColumnDefinition[],
					navigate,
					dispatch,
					t
				);
				setRowData(parseData(dataResponse.data[0], columnDefinitions));
				setFilterDefs(parseFilters(definitionResponse.data[1] as FilterDefinition[], t));
				setColDefs(columnDefinitions as unknown as ColDef[]);
				setLoading(false);
			});
		}
	}, [
		filterData,
		props.component.datasource_name,
		navigate,
		props.component.variables,
		props.component.datasource_parameters,
		dispatch,
		t
	]);

	const gridRef = useRef<AgGridReact>(null);

	function exportToXlsx() {
		let parameters: object = filterData;
		if (props.component.datasource_parameters !== null) {
			parameters = { ...filterData, ...JSON.parse(props.component.datasource_parameters) };
		}

		sendHttpRequest(
			'POST',
			'/grid/export',
			{ datasource_name: props.component.datasource_name, parameters: parameters },
			navigate,
			dispatch,
			'blob'
		).then((response) => {
			const filename = response.headers['content-disposition'].split('"')[1];
			FileDownload(response.data, filename);
		});
	}

	return (
		<>
			<div className="d-flex justify-content-between">
				{filterDefs && filterDefs.length !== 0 && (
					<form
						className="filter-form"
						onSubmit={handleSubmit(onSubmit)}
						onReset={onReset}
						noValidate>
						<div className="d-flex flex-wrap justify-content-between">
							{filterDefs?.map((filter) => (
								<div key={filter.i_table_valued_function_parameter} className="filter-item me-3">
									<Input
										name={filter.n_table_valued_function_parameter}
										label={filter.n_filter}
										type="text"
										required={false}
										register={register}
										defaultValue={filter.default_value ?? undefined}
									/>
								</div>
							))}
							<button type="submit" className="btn btn-primary me-2">
								<i className="bi bi-search"></i>
							</button>
							<button type="reset" className="btn btn-light">
								<i className="bi bi-arrow-counterclockwise"></i>
							</button>
						</div>
					</form>
				)}
				<div>
					<button type="button" className="btn btn-outline-primary" onClick={exportToXlsx}>
						<i className="me-2 bi bi-download" />
						.xlsx
					</button>
				</div>
			</div>

			<div className="ag-theme-custom mt-2 mb-4">
				<AgGridReact
					ref={gridRef}
					rowData={rowData}
					columnDefs={colDefs}
					domLayout="autoHeight"
					loadingOverlayComponent={Loading}
					pagination={true}
					paginationPageSize={20}
					loading={loading}
				/>
			</div>
		</>
	);
}
