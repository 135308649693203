import React, { useState } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { TFunction } from 'i18next';
import { Business } from 'components/Forms/ProductsForm';
import Select from 'components/Forms/FormComponents/Select';

export default function ListInformationForm(props: {
	cList: string;
	setCList: (val: string) => void;
	editDisabledByStatus: boolean;
	listInfoSaved: boolean;
	setListInfoSaved: (val: boolean) => void;
	listWeekFrom: string;
	setListWeekFrom: (val: string) => void;
	listWeekTo: string;
	setListWeekTo: (val: string) => void;
	t: TFunction<'translation', undefined>;
	submitListInfo: () => void;
	isNew: boolean;
	businesses: Business[];
	iBusiness: number | null;
	setIBusiness: (val: number) => void;
}) {
	const {
		cList,
		setCList,
		editDisabledByStatus,
		listInfoSaved,
		setListInfoSaved,
		listWeekFrom,
		setListWeekFrom,
		listWeekTo,
		setListWeekTo,
		t,
		submitListInfo,
		isNew,
		businesses,
		iBusiness,
		setIBusiness
	} = props;

	function onWeekChange(value: string, setFunc: (val: string) => void) {
		if (!(value.length > 6)) {
			setFunc(value);
		}
	}

	function setValueOnInput(value: string, setFunc: (val: string) => void) {
		setListInfoSaved(false);
		setFunc(value);
	}

	const [listWeekFromCurrent, setListWeekFromCurrent] = useState(listWeekFrom);
	const [listWeekToCurrent, setListWeekToCurrent] = useState(listWeekTo);
	const [cListCurrent, setCListCurrent] = useState(cList);

	const businessMap = new Map<string, string>(
		businesses.map((business) => [business.id.toString(), business.display_value])
	);

	return (
		<>
			<Select
				customClass="i_business-select"
				required={true}
				options={businessMap}
				name="i_business"
				label="Business"
				disabled={!isNew}
				onChange={(e) => setIBusiness(parseInt(e.target.value))}
			/>
			<Input
				name="c_list"
				type="text"
				defaultValue={cList}
				required={true}
				disabled={editDisabledByStatus}
				label="c_list"
				onChange={(e) => {
					setCList(e.target.value);
					setListInfoSaved(false);
				}}
				onInput={(e) => setValueOnInput(e.target.value, setCListCurrent)}
			/>
			<Input
				type="number"
				maxLength={6}
				required={true}
				disabled={editDisabledByStatus}
				defaultValue={listWeekFrom}
				label={t('promotion.fields.week_from')}
				name="week_from"
				onChange={(e) => onWeekChange(e.target.value, setListWeekFrom)}
				onInput={(e) => setValueOnInput(e.target.value, setListWeekFromCurrent)}
				customClass="text-end promotion-week-input"
			/>
			<Input
				type="number"
				maxLength={6}
				required={true}
				disabled={editDisabledByStatus}
				defaultValue={listWeekTo}
				label={t('promotion.fields.week_to')}
				name="week_to"
				onChange={(e) => onWeekChange(e.target.value, setListWeekTo)}
				onInput={(e) => setValueOnInput(e.target.value, setListWeekToCurrent)}
				customClass="text-end promotion-week-input"
			/>
			{!editDisabledByStatus && (
				<button
					type="button"
					className="btn btn-primary"
					onClick={submitListInfo}
					disabled={
						listInfoSaved ||
						cListCurrent === '' ||
						listWeekFromCurrent === '' ||
						listWeekToCurrent === '' ||
						(isNew && [0, null].includes(iBusiness))
					}>
					Save
				</button>
			)}
			{editDisabledByStatus && <div className="instead-of-save-btn" />}
		</>
	);
}
