import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface totalGpPercentageState {
	totalGpPercentage: number | null;
}

const initialState: totalGpPercentageState = {
	totalGpPercentage: null
};

const slice = createSlice({
	name: 'totalGpPercentage',
	initialState,
	reducers: {
		setTotalGpPercentage(state, action: PayloadAction<number>) {
			state.totalGpPercentage = action.payload;
		}
	}
});

export default slice.reducer;
export const { setTotalGpPercentage } = slice.actions;
