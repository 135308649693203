import React, { useMemo } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NotificationBlock, removeNotification } from 'state/notificationSlice';

export default function NotificationToast(props: { notification: NotificationBlock }) {
	const dispatch = useDispatch();

	const ntfClass = useMemo(() => {
		switch (props.notification.severity) {
			case 'info':
				return ' bg-primary-tint border-primary color-primary-shade';
			case 'success':
				return 'bg-green-tint border-success color-green-shade';
			case 'warning':
				return 'toast-warning bg-yellow-tint border-warning color-yellow-shade';
			case 'error':
				return 'toast-error bg-red-tint border-danger color-red-shade';
			default:
				return '';
		}
	}, [props.notification]);

	function closeToast() {
		dispatch(removeNotification(props.notification.id));
	}

	return (
		<Toast className={ntfClass} onClose={() => closeToast()}>
			{props.notification.severity === 'warning' && (
				<Toast.Header className="d-flex justify-content-between">
					<strong>
						<i className="bi bi-exclamation-circle me-2" />
						Warning
					</strong>
				</Toast.Header>
			)}
			{props.notification.severity === 'error' && (
				<Toast.Header className="d-flex justify-content-between">
					<strong>
						<i className="bi bi-exclamation-triangle-fill me-2" />
						Error
					</strong>
				</Toast.Header>
			)}
			<Toast.Body>
				{props.notification.severity === 'success' && <i className="bi bi-check-circle me-2" />}
				{props.notification.severity === 'info' && <i className="bi bi-info-circle me-2" />}

				{props.notification.message}

				{['error', 'warning'].includes(props.notification.severity) && props.notification.rq && (
					<div>
						<small>Request ID: {props.notification.rq}</small>
					</div>
				)}
			</Toast.Body>
		</Toast>
	);
}
