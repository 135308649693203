import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TranslationsState {
	translations: {
		[key: string]: { [key: string]: string };
	};
	last_update: string | null;
}

const initialState: TranslationsState = localStorage.getItem('translations')
	? JSON.parse(localStorage.getItem('translations') ?? '{}')
	: ({ translations: {}, last_update: null } as TranslationsState);

const slice = createSlice({
	name: 'translation',
	initialState,
	reducers: {
		setTranslations(
			state,
			action: PayloadAction<{ locale: string; translations: { [key: string]: string } }>
		) {
			state.translations[action.payload.locale] = action.payload.translations;
			state.last_update = new Date().toString();

			localStorage.setItem('translations', JSON.stringify(state));
		}
	}
});

export default slice.reducer;
export const { setTranslations } = slice.actions;
