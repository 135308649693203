import React from 'react';
import { getMenuItems, MenuItem } from 'utils/menu';
import { Link, useNavigate } from 'react-router-dom';
import { executeAction } from 'utils/httpRequestManager';
import { useDispatch } from 'react-redux';
import { setMenu } from 'state/menuSlice';

export default function MenuListItem(props: {
	item: MenuItem;
	setOpenedChildPosition: (pos: string) => void;
	setParentPosition: (pos: string) => void;
	ancestor: string;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onChildSelect = () => {
		props.setOpenedChildPosition(props.item.position);
		props.setParentPosition(props.ancestor);
	};

	function handleClick() {
		if (props.item.page == 'action' && props.item.action) {
			const data = JSON.parse(props.item.action);
			if (data.exec !== undefined) {
				executeAction(data.exec, navigate, dispatch).then(() => {
					getMenuItems(navigate, dispatch).then((r) => {
						if (r.children) {
							dispatch(setMenu(r.children));
						}
					});
					window.location.reload();
				});
				props.setOpenedChildPosition('');
				props.setParentPosition('');
				props.setIsOpen(false);
			}
		} else {
			props.setOpenedChildPosition('');
			props.setParentPosition('');
			props.setIsOpen(false);
			navigate(props.item.page);
		}
	}

	return (
		<li key={props.item.position} className="menu-list-item">
			{props.item.children && (
				<a
					className="d-flex justify-content-between align-items-center"
					role="button"
					onClick={() => onChildSelect()}>
					<span className="pe-5 text-nowrap">{props.item.menu_item_name}</span>
					<i className="bi-chevron-right" />
				</a>
			)}
			{!props.item.children && (
				<>
					{props.item.page !== 'action' ? (
						<Link to={props.item.page} onClick={handleClick} key={props.item.position}>
							{props.item.icon && <i className={props.item.icon + ' me-2'} />}
							<span className="pe-5">{props.item.menu_item_name}</span>
						</Link>
					) : (
						<a role="button" onClick={handleClick} key={props.item.position}>
							{props.item.icon && <i className={props.item.icon + ' me-2'} />}
							<span className="pe-5">{props.item.menu_item_name}</span>
						</a>
					)}
				</>
			)}
		</li>
	);
}
