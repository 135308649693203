import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Home() {
	const { t } = useTranslation();

	return (
		<>
			<div className="row pt-5 mt-5">
				<div className="col-12 text-center">
					<h1 className="mb-5">TESSmaster</h1>
				</div>
				<div className="col-12 d-flex justify-content-center">
					<Link to="/promotion" className="btn btn-lg btn-primary">
						{t('See all promotions')}
					</Link>
				</div>
			</div>
		</>
	);
}
