import axios, { AxiosError, ResponseType } from 'axios';
import { LoginResponse } from 'utils/auth';
import { NavigateFunction } from 'react-router-dom';
import { authItemKey, unsetUser } from 'state/userSlice';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { addNotification, removeNotification } from 'state/notificationSlice';

axios.defaults.baseURL = process.env.REACT_APP_NODE_API;
axios.defaults.headers.post['Content-Type'] = 'application/json';

let refreshingPromise: Promise<void> | null = null;

export function getAuthItem(): LoginResponse | null {
	const item = localStorage.getItem(authItemKey);
	return item ? (JSON.parse(item) as LoginResponse) : null;
}

export function isUserLoggedIn(): boolean {
	return !!getAuthItem();
}

export function setAuthItem(token: LoginResponse) {
	localStorage.setItem(authItemKey, JSON.stringify(token));
}

function clearLocalStorage() {
	localStorage.clear();
}

export function logOut(dispatch: Dispatch<UnknownAction>) {
	clearLocalStorage();
	dispatch(unsetUser());
}

async function refreshAuthToken(navigate?: NavigateFunction): Promise<void> {
	const authItem = getAuthItem();

	if (authItem) {
		const expires_at = new Date(authItem.expires_at);
		expires_at.setSeconds(expires_at.getSeconds() - 30);
		if (expires_at < new Date() && new Date(authItem.rt_expires_at) > new Date()) {
			const headers = { Authorization: `Bearer ${authItem.token}` };

			try {
				const response = await axios({
					headers,
					method: 'POST',
					url: '/refresh-token',
					data: { refresh_token: authItem.refresh_token }
				});
				setAuthItem(response.data as LoginResponse);
			} catch (error) {
				if (error instanceof AxiosError && navigate) {
					navigate('/login');
				}
			}
		}
	}
	return new Promise((resolve) => resolve());
}

export function sendHttpRequest(
	method: string,
	url: string,
	data: object | null,
	navigate?: NavigateFunction,
	dispatch?: Dispatch<UnknownAction>,
	responseType?: ResponseType
) {
	if (refreshingPromise === null) {
		refreshingPromise = refreshAuthToken(navigate);
	}
	return refreshingPromise
		.then(() => {
			refreshingPromise = null;
			const authItem = getAuthItem();

			const headers: { Authorization?: string; 'Content-Type'?: string } = authItem
				? { Authorization: `Bearer ${authItem.token}` }
				: {};

			if (data && Object.values(data).some((value) => value instanceof File)) {
				headers['Content-Type'] = 'multipart/form-data';
			} else if (data === null) {
				headers['Content-Type'] = undefined;
			}

			return axios({
				headers: headers,
				method: method,
				url: url,
				data: data,
				responseType: responseType
			});
		})
		.then((response) => {
			if (response.data.length) {
				for (const block of response.data) {
					if (block[0] !== undefined && block[0].t_l !== undefined) {
						block
							.filter((log: any) =>
								['I', 'E', 'W', 'S'].includes((log.t_l ?? ' ')[0].toUpperCase())
							)
							.forEach((log: any) => {
								let severity = 'info' as 'info' | 'success' | 'warning' | 'error';
								switch (log.t_l[0].toUpperCase()) {
									case 'E':
										severity = 'error';
										break;
									case 'W':
										severity = 'warning';
										break;
									case 'S':
										severity = 'success';
										break;
									default:
										break;
								}
								let message = log.n_l;
								if (message) {
									if (log.n_l_parameters !== null && log.n_l_parameters.startsWith('{')) {
										const params = JSON.parse(log.n_l_parameters);
										Object.entries(params).forEach(([key, value]) => {
											message = message.replaceAll(`%${key}%`, value);
										});
									}
									dispatch?.(
										addNotification({
											id: log.i_l,
											message: message,
											severity: severity,
											rq: log.rq
										})
									);
									if (severity !== 'error') {
										setTimeout(() => {
											dispatch?.(removeNotification(log.i_l));
										}, 5000);
									}
								}
							});

						break;
					}
				}
			}
			return response;
		});
}

export function executeAction(
	data: object,
	navigate: NavigateFunction,
	dispatch: Dispatch<UnknownAction>
) {
	return sendHttpRequest('POST', '/execute', data, navigate, dispatch);
}
